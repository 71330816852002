import rainway, { DataChannelMode, InputLevel, RainwayConnection } from "@rainway/web";

interface ServerStatus {
    serverId: string;
    peerId: number;
    available: boolean;
    lastUpdate: number;
}

const API_BASE = "https://server-manager.a-f88.workers.dev";

async function checkForAvailableServer(): Promise<ServerStatus | null> {
    const response = await fetch(`${API_BASE}/api/servers`);
    const data = await response.json();

    if (data.servers && data.servers.length > 0) {
        const randomIndex = Math.floor(Math.random() * data.servers.length);
        return data.servers[randomIndex];
    }

    return null;
}


document.addEventListener("DOMContentLoaded", () => {
    const games = document.querySelectorAll(".game");
    games.forEach((game) => {
        game.addEventListener("click", findServer);
    });
});

async function findServer() {
    document.querySelector('.game-selection')!.classList.add('hidden');
    const queueScreen = document.getElementById('queue-screen')!;
    queueScreen.classList.remove('hidden');

    let server: ServerStatus | null = null;
    while (!server) {
        server = await checkForAvailableServer();
        if (!server) {
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    }

    queueScreen.classList.add('hidden');
    showAd(server.peerId);
}

function showAd(peerId: number) {
    /*const adOverlay = document.getElementById('ad-overlay')!;
    adOverlay.classList.remove('hidden');

    const adVideo = document.getElementById('ad-video') as HTMLVideoElement;
    const countdownText = adOverlay.querySelector('.countdown-text')!;

    adVideo.ontimeupdate = () => {
        const timeRemaining = Math.ceil(adVideo.duration - adVideo.currentTime);
        countdownText.textContent = `Game will start in ${timeRemaining} seconds`;
    };

    adVideo.onended = () => {
        // adOverlay.classList.add('hidden');
        showLaunchScreen(peerId);
    };*/

    showLaunchScreen(peerId);
}

function showLaunchScreen(peerId: number) {
    const launchScreen = document.getElementById('launch-screen')!;
    launchScreen.classList.remove('hidden');
    startGameStream(peerId);
}

async function startGameStream(peerId: number) {
    const streamContainer = document.getElementById('stream-container')!;
    const gameStream = document.getElementById('game-stream')!;
    const gameSelection = document.querySelector('.game-selection')!;
    gameStream.classList.add('hidden');

    let conn: RainwayConnection | undefined;

    try {
        rainway.addEventListener("log", (level, target, message) => {
            console.log(`[${level}] ${target} ${message}`);
        });

        conn = await rainway.connect({ apiKey: "pk_live_rKsImIZXqkFfMRS2dd3ROlsZ", externalId: "woo" });
        console.log(`Your peer id is ${conn.id}`);

        const peer = await conn.connect(BigInt(peerId));
        const dataChannel = await peer.createDataChannel({
            id: "pathway",
            mode: DataChannelMode.Reliable,
        });

        const handleClose = () => {
            gameStream.classList.add('hidden');
            gameSelection.classList.remove('hidden');
            document.getElementById('launch-screen')!.classList.add('hidden');
            conn?.close();
            console.log("Disconnected from Rainway network.");
        };

        dataChannel.addEventListener("message", (message) => {
            const data = new TextDecoder().decode(message.data);
            if (data === "ready") {
                document.getElementById('launch-screen')!.classList.add('hidden');
                gameStream.classList.remove('hidden');
            } else if (data === "cleanup") {
                handleClose();
            }
            console.log(`Received message: ${data}`);
        });

        const stream = await peer.createStream({ permissions: InputLevel.All });
        streamContainer.appendChild(stream.container);
        stream.enableVideoStatsOverlay();

    } catch (error) {
        console.error("Failed to start Rainway stream:", error);
        gameSelection.classList.remove('hidden');
        document.getElementById('launch-screen')!.classList.add('hidden');
    }
}